import request from '@/utils/request';

/**
 * 用户登陆
 */

export const login = data => request.post('/api/userinfo/login',data)
export const getUserList = data => request.post('/api/userinfo/userList',data)
export const fileUpload = data => request.file('/api/fileUpload',data)

/**
 * 获取markdown
 */
export const getMarkdownList = data => request.post('/api/public/markdown/getMarkdownList',data)
export const getMarkdownById = data => request.post('/api/public/markdown/getMarkdownById',data)
export const getMarkdownType = data => request.post('/api/public/markdown/getMarkdownType',data)


/**
 * 获取背景音乐
 */
export const getMusicList = data => request.post('/api/public/music/getMusicList',data)

/**
 * 获取评论
 */
export const getCommentsList = data => request.post('/api/public/comments/getCommentsList',data)
export const addCommentsList = data => request.post('/api/public/comments/addCommentsList',data)
export const updateCommentsList = data => request.post('/api/public/comments/updateCommentsList',data)
