import axios from 'axios'
import qs from 'qs'
  
  // 请求拦截
  axios.interceptors.request.use(
    (config) => {
      // if (oCookie.get(["tokenTms"]).tokenTms) {
      //   config.headers.token = oCookie.get(["tokenTms"]).tokenTms;
      // }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // 响应拦截
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      return Promise.resolve(err.data);
    }
  );
  
axios.defaults.baseURL = process.env.VUE_APP_perUrl;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.timeout = 15000;
//通用code码处理
const resHandle = (res, resolve) => {
  if (res.code === 200) {
    resolve(res);
  } else {
    // MyAlert(res.msg);
    resolve();
  }
};
//生成traceid
const createTraceid = () => {
  return Math.random().toString(36).substr(2,6)
}

export default {
	get(url,params={},third_url){
		if (third_url) {
		  axios.defaults.baseURL = third_url
		} else {
		  axios.defaults.baseURL = process.env.VUE_APP_perUrl
		}
		return new Promise((resolve) => {
		  axios({
			headers: {
			  'traceid': createTraceid()
			},
			method: 'get',
			url,
			params
		  }).then(response => {
			resHandle(response.data, resolve)
		  })
		})
	},
	gets(url, params, third_url) {
	    if (third_url) {
	      axios.defaults.baseURL = third_url
	    } else {
	      axios.defaults.baseURL = process.env.VUE_APP_perUrl
	    }
	    return new Promise((resolve) => {
	      axios({
	        headers: {
	          'traceid': createTraceid()
	        },
	        method: 'get',
	        url:url + '/' + params
	      }).then(response => {
	        resHandle(response.data, resolve)
	      })
	    })
	  },
	post(url, params = {}, third_url) {
		params = {...params,createId:3}
	    if (third_url) {
	      axios.defaults.baseURL = third_url
	    } else {
	      axios.defaults.baseURL = process.env.VUE_APP_perUrl
	    }
	    return new Promise((resolve) => {
	      axios({
	        headers: {
	          'traceid': createTraceid()
	        },
	        method: 'post',
	        url,
	        data: qs.stringify(params)
	      }).then(response => {
	        resHandle(response.data, resolve)
	      })
	    })
	  },
	  postJson(url, params = {}) {
	      return new Promise((resolve) => {
	        axios({
	          headers: {
	            'traceid': createTraceid()
	          },
	          method: 'post',
	          url,
	          data: params
	        }).then(response => {

	          resHandle(response.data, resolve)
	        })
	      })
	    },
		  file(url, params = {}) {
		    const Form = new FormData()
		    for (const key in params) {
		      if (params.hasOwnProperty(key)) {
		        Form.append('file', params[key])
		      }
		    }
		    return new Promise((resolve) => {
		      axios({
		        headers: {
		          'Content-Type': 'multipart/form-data',
		          'traceid': createTraceid()
		        },
		        method: 'post',
		        timeout: 60000,
		        url,
		        data: Form
		      }).then(response => {
		        resHandle(response.data, resolve)
		      })
		    })
		  }
}